import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { CognitoToken } from "../types"

export const getTestResult = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>): Promise<string> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'GET',
        headers: {
            Authorization: tokens.access_token
        },
        url: 'https://api.einkaufsliste.nechen.de/test'
    })
    return (await getAxiosResult(getConfig, tokens, setTokens)).data as string
}

export const getRefreshedTokens = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>): Promise<CognitoToken> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'GET',
        params: {
            'refresh_token': tokens.refresh_token
        },
        url: 'https://api.einkaufsliste.nechen.de/refreshToken'
    })
    return {refresh_token: tokens.refresh_token, ...(await getAxiosResult(getConfig, tokens, setTokens, true)).data as {id_token: string, access_token: string}} 
}

export const createItem = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>, itemName: string): Promise<any> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'POST',
        headers: {
            Authorization: tokens.access_token
        },
        data: {
            itemName
        },
        url: 'https://api.einkaufsliste.nechen.de/createItem'
    })
    return (await getAxiosResult(getConfig, tokens, setTokens))
}

export const deleteItem = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>, id: string): Promise<any> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'POST',
        headers: {
            Authorization: tokens.access_token
        },
        data: {
            itemID: id
        },
        url: 'https://api.einkaufsliste.nechen.de/deleteItem'
    })
    return (await getAxiosResult(getConfig, tokens, setTokens))
}

export const getItems = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>): Promise<any[]> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'GET',
        headers: {
            Authorization: tokens.access_token
        },
        url: 'https://api.einkaufsliste.nechen.de/getItems'
    })
    return (await getAxiosResult(getConfig, tokens, setTokens)).data
}

export const updateItem = async (tokens: CognitoToken, setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>, id:string, newValue: boolean): Promise<any> => {
    const getConfig = (tokens: CognitoToken): AxiosRequestConfig => ({
        method: 'POST',
        headers: {
            Authorization: tokens.access_token
        },
        data: {
            id,
            updates: {
                valid: {
                    Action: 'PUT',
                    Value: {
                        BOOL: newValue
                    }
                }
            }
        },
        url: 'https://api.einkaufsliste.nechen.de/updateItem'
    })
    return (await getAxiosResult(getConfig, tokens, setTokens)).data
}

const getAxiosResult = async (getConfig: (tokens: CognitoToken) => AxiosRequestConfig, tokens: CognitoToken, 
    setTokens: React.Dispatch<React.SetStateAction<CognitoToken>>, isRefreshed: boolean = false): Promise<AxiosResponse> => {
    return axios.request(getConfig(tokens)).then(data => {
        return data
    }).catch(async err => {
        if (!isRefreshed) {
            const newTokens: CognitoToken = await getRefreshedTokens(tokens, setTokens)
            setTokens(newTokens)
            return getAxiosResult(getConfig, newTokens, setTokens, true)
        } else {
            console.log(err)
            throw err
        }
    })
}