import { useEffect, useRef, useState } from "react"

export const useWs = () => {
    const [isReady, setIsReady] = useState(false)
    const [val, setVal] = useState<any>(null)

    const ws = useRef(null)
    useEffect(() => {
        const socket = new WebSocket('wss://wss.einkaufsliste.nechen.de')
        socket.onopen = () => setIsReady(true)
        socket.onclose = () => setIsReady(false)
        socket.onmessage = (event) => setVal(JSON.parse(event.data))
        return () => socket.close()
    },[])
    return [isReady, val]
}