import React, { useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandOutput } from '@aws-sdk/client-cognito-identity-provider';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { createItem, deleteItem, getItems, getTestResult, updateItem } from './api';
import { CognitoToken, Item } from './types';
import { useWs } from './api/websocket';


const dbItemToItemType = (dbItem: any, key: number): Item => {
  return {
    date: dbItem['date']['N'],
    id: dbItem['id']['S'],
    key,
    name: dbItem['entryName']['S'],
    valid: dbItem['valid']['BOOL']
  }
}


function App() {

  const DEV: boolean = process.env.NODE_ENV === 'development'
  const [urlParams, setUrlParams] = useSearchParams()
  const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false)
  const [items, setItems] = React.useState<Item[]>([])
  const [newItemName, setNewItemName] = React.useState<string>('')
  const [tokens, setTokens] = React.useState<CognitoToken>({
    access_token: '',
    id_token: '',
    refresh_token: ''
  })
  const [isItemsLoaded, setIsItemsLoaded] = React.useState<boolean>(false)

  const checkAuth = useCallback(async () => {
    const result = await (await axios.get('https://api.einkaufsliste.nechen.de/loginWebsite',
      { params: DEV ? { dev: true } : {} })).data
    const code = urlParams.get('code')
    if (code) {
      urlParams.delete('code')
      setUrlParams(urlParams)
      const { id_token, access_token, refresh_token } = (await axios.get('https://api.einkaufsliste.nechen.de/codeToToken', {
        params: {
          code,
          ...(DEV ? { dev: true } : {})
        }
      })).data
      setTokens({ access_token, id_token, refresh_token })
      setIsAuthorized(true)
    } else {
      setIsAuthorized(false)
      window.location.assign(result)
    }
  }, [])

  const fetchItems = useCallback(async (tokens: CognitoToken) => {
    const itemResult: Item[] = [];
    (await getItems(tokens, setTokens)).forEach((e: any) => {
      itemResult.push(dbItemToItemType(e, itemResult.length + 1))
    })
    setItems(itemResult)
  }, [])
  const [isReady, val] = useWs()
  React.useEffect(() => {
    checkAuth()
  }, [])

  React.useEffect(() => {
    if (isAuthorized && tokens.access_token !== '' && !isItemsLoaded) {
      fetchItems(tokens)
      setIsItemsLoaded(true)
    }
  }, [isAuthorized, tokens, isItemsLoaded])

  React.useEffect(() => {
    if (!val)
      return
    console.log(val)
    switch (val['action']) {
      case 'CREATE':
        setItems(prev=>
        [...prev,
          dbItemToItemType(val['item'], prev.length+1)
        ]
        )
        break
      case 'UPDATE':
        setItems(prev =>
          [
            ...prev.filter(item => item.id !== val['item']['id']['S']),
            dbItemToItemType(val['item'], prev.filter(item => item.id === val['item']['id']['S'])[0].key)
          ]
        )
        break
      case 'DELETE':
        setItems(prev =>
          [...prev.filter(item => item.id !== val['id'])]
        )
        break
    }
  }, [val])

  React.useEffect(() => {
    console.log(items)
  },[items])


  return (
    <>
      {isAuthorized ?
        <>
          {isItemsLoaded ? items.map(item => <>{item.valid ? item.name : <s>{item.name}</s>}
            <input type='button' value='Delete' onClick={async () => deleteItem(tokens, setTokens, item.id)} /><br />
            <input type='button' value='Unvalid' onClick={async () => updateItem(tokens, setTokens, item.id, !item.valid)} /></>) : 'Loading Items...'}
          <br /><input type='text' value={newItemName} onChange={e => setNewItemName(e.target.value)} />
          <input type='button' value='Add' onClick={async () => createItem(tokens, setTokens, newItemName)} />
        </>
        : 'Authorizing...'}

    </>
  );
}

export default App;
