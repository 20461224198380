import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import React from "react";

function Routing(){
    const [sessionStorageCode, setSessionStorageCode] = React.useState<string |null>('')

    React.useEffect(() => {
        setSessionStorageCode(window.sessionStorage.getItem('code'))
    },[])

    
    return(
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}/>
        </Routes>
        </BrowserRouter>
    )
}

export default Routing